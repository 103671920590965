import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
const Home= () => import('../views/Home.vue')
const Login= () => import('../views/Login.vue')
const Case= () => import('../components/Case.vue')
const Casedetail= () => import('../components/Casedetail.vue')
const Contract= () => import('../components/Contract.vue')
const Index= () => import('../components/Index.vue')
const Monitoring= () => import('../components/Monitoring/Monitoring.vue')
const Import= () => import('../components/Import.vue')
const Annualfee= () => import('../components/Annualfee.vue')
const Order= () => import('../components/Order.vue')
const Trade= () => import('../components/Trade.vue')
const Releasetrans= () => import('../components/Releasetrans.vue')
const Releasepur= () => import('../components/Releasepur.vue')
const Recharge= () => import('../components/Recharge.vue')
const Confirm= () => import('../components/Confirm.vue')
const Appointment= () => import('../components/Appointment.vue')
const Account= () => import('../components/Account.vue')
const Coupon= () => import('../components/Coupon.vue')
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path:'/login',
    component:Login
  },
  {
    path: '/home',
    component:Home,
    redirect: '/index',
    children: [
      {path: '/index',component:Index},
      {path: '/case',component:Case},
      {path: '/casedetail',component:Casedetail},
      {path: '/contract',component:Contract},
      {path: '/monitoring',component:Monitoring},
      {path: '/annualfee',name:'annualfee', component:Annualfee}, 
      {path: '/import',component:Import},
      {path: '/order',component:Order},
      {path: '/trade',component:Trade},
      {path: '/releasetrans',component:Releasetrans},
      {path: '/releasepur',component:Releasepur},
      {path: '/recharge',component:Recharge},
      {path: '/confirm',component:Confirm},
      {path: '/appointment',component:Appointment},
      {path: '/account',component:Account},
      {path: '/coupon',component:Coupon}

    ]
  },
]

const router = new VueRouter({
  // mode: 'history',
  routes
})
router.beforeEach((to, from, next) => {
   if(to.path== "/login") return next()
    let token=window.localStorage.token
   if(!token) return next('/login')
   window.sessionStorage.setItem('active',to.path)
   store.commit('increment', to.path)
   next()
})
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
});

export default router
