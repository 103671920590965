import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    active: '',
    loading: false,
    infor: {},
  },
  mutations: {
    increment(state, n) {
      state.active = n
    },
    load(state, n) {
      state.loading = n
    },
    getinfor(state, n) {
      state.infor = n
    }
  },
  actions: {
  },
  modules: {
  }
})
