import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import './css/global.css'
import axios from 'axios'
var _hmt = _hmt || [];
window._hmt = _hmt; // 修改为window 全局变量
(function() {
  var hm = document.createElement("script");
  //test
  // hm.src = "https://hm.baidu.com/hm.js?40a53ac98b814d2250096cd79527be31";
  // jk
  hm.src = "https://hm.baidu.com/hm.js?68d58f1099c8db36c9b2f3c95a757423";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();
const baseURL = window.location.protocol + '//' + window.location.host + '/';
axios.defaults.baseURL = baseURL
axios.defaults.withCredentials = true
axios.interceptors.request.use(function (config) {
if(config.url.indexOf('pay/index/payStatus?')==-1){
  store.commit('load', true)
}
  let token = window.localStorage.token
  config.headers.Authorization = token
  return config
});
axios.interceptors.response.use(function (response) {
  store.commit('load', false)
  if(response.headers.token){
    window.localStorage.token=response.headers.token
  }
  
  if (response.data.code == 401) {
    window.localStorage.clear()
    window.location.href = "/";
    return
  }
  if(response.data.msgStatus){
    if (response.data.code == 400||response.data.code == 401) {
      ElementUI.Message({
        message: response.data.msg,
          });
    }

    if (response.data.code == 200) {
     
      ElementUI.Message({
        message: response.data.msg,
        type: 'success'
    });
    }
  }
  
  return response;
},err=>{
  store.commit('load', false)
  ElementUI.Message({
    message:'请求失败',
})

});
Vue.prototype.$request = axios
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
